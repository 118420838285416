import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedMaterialModule } from './shared-material.module';

@NgModule({
  declarations: [
  ],
  exports: [
    FlexLayoutModule,
    SharedMaterialModule,
  ]
})
export class SharedModule {}
