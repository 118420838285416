<section class="section__main main-text-content" fxLayout="column" fxLayoutAlign="flex-start center" fxLayoutGap="50px">
  <ng-container *ngIf="news.length; else elseTemplate">
    <div *ngFor="let item of news">
      <div fxLayout="column" fxLayoutAlign="flex-start center" fxLayoutGap="10px" [id]="'news-' + item.id">
        <h1>{{item.title}}</h1>
        <p class="date">{{item.dateString}}</p>
        <div class="line"></div>
      </div>
      <div class="news-box2" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="7px">
        <div class="news-content-container2" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center center" fxLayoutGap.lt-sm="10px">
          <div class="img-box2">
            <img [src]="item.imgBig" alt="obrazek-artykul.jpg" height="225" width="auto">
          </div>
          <p class="content-text pre-wrap">{{item.mainText}}</p>
          <div style="clear: both;"></div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <h1>Wczytywanie aktualności...</h1>
  </ng-template>
</section>