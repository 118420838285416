import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { NewsComponent } from './main/news/news.component';
import { PurchaseComponent } from './main/purchase/purchase.component';
import { AboutUsComponent } from './main/about-us/about-us.component';
import { OfferComponent } from './main/offer/offer.component';

const routes: Routes = [
  {
    path: '',
    component: WelcomeComponent,
    data: {
      title: 'Komis gastronomiczny - Witaj',
      description: 'Sprzedaż, kupno wyposażenia, sprzętu gastronomicznego, biurowego. Maszyn gastromicznych.',
      keywords: 'komis, gastronomiczny, wyposazenie, skup, sprzedaz, biurowy, gastronomia, maszyny, gastronomiczne',
    },
  },
  {
    path: 'aktualnosci',
    component: NewsComponent,
    data: {
      title: 'Aktualności - Komis gastronomiczny',
      description: 'Najnowsze nowinki z branży gastronomicznej',
      keywords: 'komis, gastronomiczny, wyposazenie, wyposazenie, biurowe, gastronomia, maszyny, gastronomiczne',
    },
  },
  {
    path: 'skup-sprzetu-wyposazenia',
    component: PurchaseComponent,
    data: {
      title: 'Skup, sprzedaż, wyposażenie',
      description: 'Skupujemy wyposazenie, sprzęt i maszyny gastronomiczne.',
      keywords: 'komis, gastronomiczny, wyposazenie, skup, sprzedaz, biurowy, gastronomia, maszyny, gastronomiczne',
    },
  },
  {
    path: 'oferta',
    component: OfferComponent,
    data: {
      title: 'Oferta - Komis gastronomiczny',
      description: 'Zabacz naszą ofertę sprzętu gastronomicznego. Mszyny, wyposażenie gastronomiczne.',
      keywords: 'komis, gastronomiczny, wyposazenie, skup, sprzedaz, biurowy, gastronomia, maszyny, gastronomiczne, oferta',
    },
  },
  {
    path: 'o-nas',
    component: AboutUsComponent,
    data: {
      title: 'Komis gastronomiczny - o nas',
      description: 'Dowiedz się więcej o nas',
      keywords: 'komis, gastronomiczny, wyposazenie, skup, sprzedaz, biurowy, gastronomia, maszyny, gastronomiczne, oferta, opis, o nas',
    },
  },
  { path: 'formularz', loadChildren: () => import('./modules/mail-forms/mail-forms.module').then(module => module.MailFormsModule)},
  { path: 'kontakt', redirectTo: 'formularz/kontaktu' },
  { path: 'formularz-wyceny', redirectTo: 'formularz/wyceny' },
  {
    path: '**', redirectTo: '',
    component: WelcomeComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
              routes,
              {
                scrollPositionRestoration: 'enabled',
                // preloadingStrategy: PreloadAllModules,
              },
            )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
