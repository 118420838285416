import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { News } from '../models/news.model';
import { links } from '../shared/globalVariables';
import { NewsService } from '../shared/services/news.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit, OnDestroy {

  constructor(private newsService: NewsService) {}

  public title = 'Witaj w Komisie Gastronomia';
  public links = links;
  public newsLastTwo: News[] = [];

  private newsChanged: Subscription = new Subscription();

  ngOnInit(): void {
    this.newsChanged = this.newsService.newsChanges.subscribe(
      (news: News[]) => {
        this.newsLastTwo = news.slice(-2);
      }
    );
    this.newsLastTwo = this.newsService.getAllNews().slice(-2);
  }

  ngOnDestroy(): void {
    this.newsChanged.unsubscribe();
  }
}
