import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Output() public sidenavToggle = new EventEmitter<void>();

  constructor() { }

  public routesLinksArray = [
    { text: 'Aktualności', link: 'aktualnosci'},
    { text: 'Oferta', link: 'oferta'},
    { text: 'Skup\nsprzętu', link: 'skup-sprzetu-wyposazenia'},
    { text: 'O nas', link: 'o-nas'},
    { text: 'Kontakt, Formularz', link: 'formularz/kontaktu'},
    { text: 'Formularz wyceny', link: 'formularz/wyceny'},
  ];

  public onToggleSidenav(): void {
    this.sidenavToggle.emit();
  }

}
