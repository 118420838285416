import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MetaService {
  constructor(private title: Title, private meta: Meta) { }

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url });
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
    this.meta.updateTag({ name: 'og:description', content: desc });
    this.meta.updateTag({ name: 'og:url', content: environment.url + document.location.pathname });
  }

  updateKeywords(str: string) {
    this.meta.updateTag({ name: 'keywords', content: str });
  }
}
