import { Component } from '@angular/core';
import { NewsService } from './shared/services/news.service';
import { RouteMetaService } from './shared/services/route-meta.service';
import { SendmailService } from './shared/services/sendmail.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(
    private routeMetaService: RouteMetaService,
    private newsService: NewsService,
    private sendmailService: SendmailService,
  ) {
    this.routeMetaService.setTagsInRoutes();
    this.newsService.fetchAllNews();
    this.gS().then( str => {
      this.sendmailService.setSecret(str);
    });
  }

  private async gS(): Promise<string> {
    const d = new Date();
    const extraStr = `${d.getDate() * (d.getDay() + d.getMonth())}`;
    const arr = await this.shuffle();
    let strReturn = '';
    for (let index = 0; index < 4; index++) {
      strReturn += Math.trunc((arr[index] + Math.random()) * 100);
      if (index === 1) {
        strReturn += extraStr;
      }
    }
    return strReturn;
  }

  private async shuffle() {
    const array = [1, 2, 3, 4];
    let currentIndex = array.length;
    let randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
  }

}
