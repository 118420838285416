import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { News } from 'src/app/models/news.model';

@Injectable({ providedIn: 'root' })

export class NewsService implements OnDestroy {
  constructor(private readonly http: HttpClient) {}

  private news: News[] = [];

  public newsChanges = new Subject<News[]>();

  public fetchAllNews() {
    this.http.get<News[]>('/assets/news/news-data.json').subscribe(
      newsJson => {
        this.news = newsJson;
        this.newsChanges.next([...this.news]);
      }
    );
  }

  public getAllNews() {
    return [...this.news];
  }

  public ngOnDestroy(): void {
    this.newsChanges.unsubscribe();
  }
}
