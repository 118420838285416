import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MetaService } from './meta.service';
import { filter, mergeMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouteMetaService {
  constructor(
    private metaService: MetaService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  public setTagsInRoutes() {
    this.router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
    .subscribe((event) => {
      this.metaService.updateTitle(event['title']);
      this.metaService.updateOgUrl(event['ogUrl']);
      this.metaService.updateKeywords(event['keywords']);
      this.metaService.updateDescription(`${event['title']} - ${event['description']}`);
    });
  }
}
