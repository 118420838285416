import { AfterViewInit, Component } from '@angular/core';
import { links } from '../shared/globalVariables';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements AfterViewInit {

  constructor() {}

  public footerContactData = [
    {
      text: {
        line1: '517 371 530',
        line2: '515 433 298',
      },
      icon: {
        src: '/assets/images/icons/call-phone-footer.svg',
        alt: 'telefon',
      },
    },
    {
      text: {
        line1: 'kontakt@komisgastronomia.pl',
        line2: '',
      },
      icon: {
        src: '/assets/images/icons/email-contact.svg',
        alt: 'poczta',
      },
    },
    {
      text: {
        line1: '43-227 Góra (obok Pszczyny)',
        line2: '',
      },
      icon: {
        src: '/assets/images/icons/place-location.svg',
        alt: 'lokalizacja',
      },
    },
  ];

  public year = 2021;
  public links = links;

  private async getYear(): Promise<number> {
    const newDate = new Date();
    return newDate.getFullYear();
  }

  public ngAfterViewInit(): void {
    this.getYear().then( year => {
      this.year = year;
    });
  }

}
