<section class="section__main main-text-content" fxLayout="column" fxLayoutAlign="flex-start flex-start" fxLayoutGap="20px">
  <div class="title-container" fxLayout="column" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
    <h1>Skup sprzętu, wyposażenia</h1>
    <div class="line"></div>
  </div>
  <p>Jedną z naszych specjalizacji jest skup sprzętu oraz wyposażenia. Oferujemy najwyższy standardy, a każdy etap naszych prac jest z Państwem konsultowany. Gwarantujemy, że całość przebiegnie bezstresowo, z satysfakcją dla obu stron.</p>
  <ul>
    <h2>Skupujemy sprzęt oraz wyposażenia:</h2>
    <li>restauracji;</li>
    <li>stołówek;</li>
    <li>barów;</li>
    <li>pubów;</li>
    <li>pensjonatów;</li>
    <li>jadłodajni;</li>
    <li>hoteli;</li>
    <li>uzdrowisk;</li>
    <li>wypożyczalni sprzętu;</li>
    <li>sklepów;</li>
    <li>innych nie wymienionych powyżej.</li>
  </ul>
  <p>Oczywiście to tylko niektóre z naszych ostatnich realizacji. Jeśli nie znaleźli Państwo swojej dziedziny zapraszamy do kontaktu, rozwiejemy wszelkie wątpliwości i wyjaśnimy cały proces.</p>
  <ul>
    <h2>Co nas wyróżnia?</h2>
    <li>Konkurencyjne ceny adekwatne do posiadanego sprzętu;</li>
    <li>Możliwość pracy w godzinach nocnych;</li>
    <li>Szybkość działania (gwarantujemy natychmiastowe rozpoczęcie prac);</li>
    <li>Płatność gotówką na miejscu;</li>
    <li>Obsługę klientów na terenie Polski, jak również poza granicami;</li>
    <li>Profesjonalne podejście do każdej transakcji;</li>
    <li>Kompletną obsługę od A do Z.</li>
  </ul>
  <ol>
    <h2>Jak przebiega cały proces?</h2>
    <li>Skontaktuj się z nami poprzez formularz lub numer kontaktowy;</li>
    <li>Wyznacz termin spotkania, które umożliwi nam wycenę towaru. Jeśli odległość jest zbyt duża wycenimy towar na podstawie przesłanych zdjęć lub spisu inwentaryzacyjnego;</li>
    <li>Po negocjacjach i akceptacji naszej wyceny wspólnie wyznaczymy dogodny termin odbioru sprzętu;</li>
    <li>Odbiór wyposażenie/sprzętu i wypłata wcześniej ustalonej kwoty;</li>
    <li>Ostatni etap – uporządkowanie lokalu.</li>
  </ol>
  <p>Już teraz <b>zadzwoń do nas</b>, a my zajmiemy się całą resztą.</p>
</section>