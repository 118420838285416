function easeInOutQuad(t: number, b: number, c: number, d: number) {
  // t = current time
  // b = start value
  // c = change in value
  // d = duration
  t /= d / 2;
  if (t < 1) { return c / 2 * t * t + b; }
  t--;
  return -c / 2 * (t * (t - 2) - 1) + b;
}

export function scrollToPosition(toPosition: number, duration: number) {
  const element = document.scrollingElement || document.documentElement;
  const start: number = +element.scrollTop;
  const change: number = toPosition - start;
  const startDate = +new Date();

  const animateScroll = () => {
    const currentDate = +new Date();
    const currentTime: number = currentDate - startDate;
    element.scrollTop = easeInOutQuad(currentTime, start, change, duration);
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      element.scrollTop = toPosition;
    }
  };
  animateScroll();
}
