import { Component, HostListener } from '@angular/core';
import { scrollToPosition } from '../../shared/extraMainFunctions';

@Component({
  selector: 'app-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss']
})
export class ScrollTopComponent {

  public hideBtunScrollTop = true;

  constructor() { }

  @HostListener('window:scroll', ['$event'])
  public onScroll() {
    this.arrowUpControl(window.scrollY);
  }

  private arrowUpControl(scrolY: number) {
    const heightCondition = window.innerHeight / 2;
    this.hideBtunScrollTop = scrolY > heightCondition ? false : true;
  }

  public scrollToTop() {
    scrollToPosition(0, 900);
  }
}
