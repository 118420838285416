import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MailContactDTO } from 'src/app/models/DTO/mail-contact.dto';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })

export class SendmailService {
  constructor(
    private http: HttpClient,
  ) {}

  private secret = 'secret';

  public contactForm(mailContactDTO: MailContactDTO) {
    const secret = this.secret;
    return this.http.post<MailContactDTO>(`${environment.apiUrl}/mail/contact`, {...mailContactDTO, secret});
  }
  public valuationForm(data: FormData) {
    data.append('secret', this.secret);
    return this.http.post<any>(`${environment.apiUrl}/mail/valuation`, data);
  }

  public setSecret(str: string) {
    this.secret = str;
  }
}
