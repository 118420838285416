import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WelcomeComponent } from './welcome/welcome.component';
import { HeaderComponent } from './navigation/header/header.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { AboutUsComponent } from './main/about-us/about-us.component';
import { NewsComponent } from './main/news/news.component';
import { PurchaseComponent } from './main/purchase/purchase.component';
import { FooterComponent } from './footer/footer.component';
import { MediaLinksComponent } from './navigation/media-links/media-links.component';
import { OfferComponent } from './main/offer/offer.component';
import { NewsService } from './shared/services/news.service';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { ScrollTopComponent } from './navigation/scroll-top/scroll-top.component';
import { SendmailService } from './shared/services/sendmail.service';
import { MetaService } from './shared/services/meta.service';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './modules/shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidenavListComponent,
    WelcomeComponent,
    AboutUsComponent,
    NewsComponent,
    PurchaseComponent,
    OfferComponent,
    ScrollTopComponent,
    MediaLinksComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ScullyLibModule,
    SharedModule,
  ],
  providers: [
    MetaService,
    NewsService,
    SendmailService,
  ],
  schemas: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
