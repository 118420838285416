<header>
  <mat-toolbar id="navbar-main" color="primary" fxLayout="row">
    <nav class="navbar-content" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center">
      <div fxHide.gt-sm >
        <div class="icon-container" fxLayout="row" fxLayoutAlign="center center" (click)="onToggleSidenav()">
          <img src="/assets/images/icons/menu_white_36dp.svg" alt="ikonka-menu">
        </div>
      </div>
      <div class="logo-container">
        <a routerLink="/" fxLayout fxLayoutAlign="center center">
          <img src="assets/images/logo.png" alt="logo-komis-gastronomia" height="67px" width="245px">
        </a>
      </div>
      <ul class="navigation-items" fxLayout="row wrap" fxLayoutAlign="center center" fxHide fxShow.gt-sm role="navigation">
        <li *ngFor="let item of routesLinksArray" fxLayout="row" fxLayoutAlign="center center" routerLinkActive="active">
          <a class="pre-wrap" [routerLink]="'/'+item.link" routerLinkActive="active" fxLayout="column" fxLayoutAlign="space-between center">
            <div class="line"></div>
            <span>{{item.text}}</span>
            <div class="line"></div>
          </a>
        </li>
      </ul>
    </nav>
  </mat-toolbar>
</header>
