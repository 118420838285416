<footer fxLayout="column" fxLayoutAlign="start start">
  <div fxLayout="row" class="logo-container" fxLayoutAlign="center center">
    <img src="/assets/images/logo.png" alt="logo-komis-gastronomia" height="76px" width="276px">
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="footer-content-container">
    <div fxLayout="column" fxLayoutAlign="start center" class="footer-contact">
      <h3>Kontakt</h3>
      <ul fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <li *ngFor="let item of footerContactData" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="center center" fxLayout.xs="column" fxLayoutGap="15px" fxLayoutGap.xs="5px">
          <img [src]="item.icon.src" [alt]="item.icon.alt" width="30" height="30">
          <div fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.xs="center center" fxLayoutGap="5px">
            <p>{{item.text.line1}}</p>
            <p>{{item.text.line2}}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="footer-websites" fxLayout="column" fxLayoutAlign="start center">
      <h3>Godziny otwarcia:</h3>
      <img src="/assets/images/icons/schedule-time.svg" alt="czas" width="30" height="30">
      <ul fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
        <li fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" fxLayoutGap.xs="5px">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
            <p>Poniedziałek - Piątek:</p>
            <p>15:00 - 20:00</p>
          </div>
        </li>
       <li fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" fxLayoutGap.xs="5px">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
            <p>Sobota:</p>
            <p>8:00 - 20:00</p>
          </div>
        </li>
       <li fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" fxLayoutGap.xs="5px">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
            <p>Niedziela:</p>
            <p>Godziny pracy ustalane są indywidualnie</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="footer-ms" fxLayout="column" fxLayoutAlign="start center">
      <h3>Tu jesteśmy:</h3>
      <ul fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" fxLayoutAlign.xs="center center">
        <li>
          <a [href]="links.facebook" target="_blank" rel="noopener" fxLayout="row wrap" fxLayoutAlign="start center">
            <div class="twarzksiazka" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/facebook-letter.svg" alt="ikonka" height="31" width="16">
            </div>
            <span>Facebook</span>
          </a>
        </li>
        <li>
          <a [href]="links.allegro" target="_blank" rel="noopener" fxLayout="row wrap" fxLayoutAlign="start center">
            <div class="allegro" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/allegro-ikonka.svg" alt="allegro-ikonka" height="45" width="45">
            </div>
            <span>Allegro</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</footer>
<div class="copyright">
  <p>COPYRIGHT © {{year}} KomisGastronomia.pl</p>
</div>