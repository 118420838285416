import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss'],
})
export class SidenavListComponent {
  @Output() public closeSidenav = new EventEmitter<void>();

  constructor() {}

  public routesLinksArray = [
    { text: 'Aktualności', link: 'aktualnosci', icon: 'web'},
    { text: 'Oferta', link: 'oferta', icon: 'request_page'},
    { text: 'Skup sprzętu', link: 'skup-sprzetu-wyposazenia', icon: 'point_of_sale'},
    { text: 'O nas', link: 'o-nas', icon: 'people_alt'},
    { text: 'Kontakt, Formularz', link: 'formularz/kontaktu', icon: 'email'},
    { text: 'Formularz wyceny', link: 'formularz/wyceny', icon: 'price_change'},
  ];

  public returnPathIcon(isActive: boolean, iconName: string): string {
    const color = isActive ? 'white' : 'black';
    return `/assets/images/icons/${iconName}_${color}.svg`;
  }

  public onClose(): void {
    this.closeSidenav.emit();
  }
}
