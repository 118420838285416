<mat-sidenav-container>
  <mat-sidenav #sidenav role="navigation">
    <app-sidenav-list (closeSidenav)="sidenav.close()"></app-sidenav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
    <main>
      <router-outlet></router-outlet>
    </main>
    <app-footer></app-footer>
    <app-media-links></app-media-links>
    <app-scroll-top></app-scroll-top>
  </mat-sidenav-content>
</mat-sidenav-container>
