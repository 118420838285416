import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { News } from 'src/app/models/news.model';
import { NewsService } from 'src/app/shared/services/news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, OnDestroy {

  constructor(private newsService: NewsService) {}

  public news: News[] = [];

  private newsChanged: Subscription = new Subscription();

  ngOnInit(): void {
    this.newsChanged = this.newsService.newsChanges.subscribe(
      (news: News[]) => {
        this.news = news.reverse();
      }
    );
    this.news = this.newsService.getAllNews().reverse();
  }

  ngOnDestroy(): void {
    this.newsChanged.unsubscribe();
  }

}
